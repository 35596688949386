import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.scss";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";

/* Images */
import ArrowRight from "assets/images/about-us/arrow-right.svg";
import Image1 from "assets/images/about-us/goldbars.svg";
import Image2 from "assets/images/about-us/pawn.svg";
import Image3 from "assets/images/about-us/etherium.svg";
import Image4 from "assets/images/about-us/mint-gpo.svg";
import Image5 from "assets/images/about-us/mint-gpx.svg";
import Image6 from "assets/images/about-us/list-gpx.svg";
import Image7 from "assets/images/about-us/handshake.svg";
import Image8 from "assets/images/about-us/blockchain.svg";
import Image9 from "assets/images/about-us/graph.svg";
// import CoinMarketCap from "assets/images/about-us/coin-market-cap.svg";
// import CoinGecko from "assets/images/about-us/coin-gecko.svg";
import GPO from "assets/images/about-us/gpo-large.svg";
import GPX from "assets/images/about-us/gpx-large.svg";
import ExternalLink from "assets/images/icons/external-link.svg";

import { ImageRotationContext } from '../../../src/ImageRotationContext';

const AboutUs = () => {
  const isMobileView = window.innerWidth <= 768;
  const { rotateImage } = useContext(ImageRotationContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const launchAppFunc = () => {
    window.location.href = "https://app.goldpesa.com/";
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, [2500]);
  }, []);

  return (
    <div className="container">
      <Loader isLoading={isLoading} />

      <div className={styles.aboutUs}>
        <section className={styles.aboutUs__section}>
          <div className={styles.aboutUs__top}>
            <h1 className="heading-2 center">{t("About Us")}</h1>

            <div className={styles.aboutUs__layout}>
              <div className={styles.aboutUs__cardsWrapper}>
                {/* First Card */}
                <div className={styles.card1}>
                  <div className={styles.card1__wrapper}>
                    <div className={styles.card1__top}>
                      <h2 className={styles.card1__title}>
                        {t("Why buy GoldPesa (GPX) ?")}
                      </h2>
                    </div>

                    <div className={styles.card1__bottom}>
                      {/* Card 1-1 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_1}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image1} alt="Goldbars" />
                          </figure>
                          <h2 className={styles.cardItem__title}>
                            {t("Digital Gold")}
                          </h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("GPX captures the essence of gold in a purely digital form.")}
                          </p>
                        </div>
                      </div>

                      {/* Card 1-2 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_2}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image2} alt="Pawn" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("The Pawn")}</h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("A profitable algorithm that buys and burns GPX tokens.")}
                          </p>
                        </div>
                      </div>

                      {/* Card 1-3 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_3}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image3} alt="Etherium" />
                          </figure>
                          <h2 className={styles.cardItem__title}>
                            {t("Trustless")}
                          </h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("Like Bitcoin, GoldPesa is fully decentralized and beyond anyone's control.")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Second Card */}
                <div className={styles.card1}>
                  <div className={styles.card1__wrapper}>
                    <div className={styles.card1__top}>
                      <h2 className={styles.card1__title}>{t("The plan")}</h2>
                    </div>

                    <div className={styles.card1__bottom}>
                      {/* Card 1-1 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_1}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <h3 className={styles.cardItem__subTitle}>{t("Phase 1")}</h3>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image4} alt="Mint GPO" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("Mint GPO")}</h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("Buy GoldPesa Option (GPO)")}
                          </p>
                        </div>
                      </div>

                      <div
                        className="arrowImg"
                        style={{ display: isMobileView ? 'none' : 'block' }}
                      >
                        <img
                          src={ArrowRight}
                          alt="Arrow Right"
                          style={{ transform: rotateImage ? 'rotate(180deg)' : 'rotate(0deg)' }}
                        />
                      </div>

                      {/* Card 1-2 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_2}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <h3 className={styles.cardItem__subTitle}>{t("Phase 2")}</h3>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image5} alt="Mint GPX" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("Mint GPX")}</h2>
                          <p className={styles.cardItem__tagLine}>
                           {t("Convert GPO into GPX")}
                          </p>
                        </div>
                      </div>

                    <div
                        className="arrowImg"
                        style={{ display: isMobileView ? 'none' : 'block' }}
                      >
                        <img
                          src={ArrowRight}
                          alt="Arrow Right"
                          style={{ transform: rotateImage ? 'rotate(180deg)' : 'rotate(0deg)' }}
                        />
                      </div>

                      {/* Card 1-3 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_3}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <h3 className={styles.cardItem__subTitle}>{t("Phase 3")}</h3>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image6} alt="List GPX" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("List GPX")}</h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("Trade GPX on Uniswap")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Third Card */}
                <div className={styles.card1}>
                  <div className={styles.card1__wrapper}>
                    <div className={styles.card1__top}>
                      <h2 className={styles.card1__title}>
                        {t("Where is my gold? What if crypto markets crash?")}
                      </h2>
                    </div>

                    <div className={styles.card1__bottom}>
                      {/* Card 1-1 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_1}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image7} alt="Handshake" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("Trusted")}</h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("GPX is backed by code, NOT by physical gold.")}
                          </p>
                        </div>
                      </div>

                      {/* Card 1-2 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_2}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image8} alt="Blockchain" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("Fair Launch")}</h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("USDC cannot be removed from liquidity.")}
                          </p>
                        </div>
                      </div>

                      {/* Card 1-3 */}
                      <div
                        className={`${styles.cardItem} ${styles.cardItem_3}`}
                      >
                        <div className={styles.cardItem__wrapper}>
                          <figure className={styles.cardItem__figure}>
                            <img src={Image9} alt="Graph" />
                          </figure>
                          <h2 className={styles.cardItem__title}>{t("Safe Haven")}</h2>
                          <p className={styles.cardItem__tagLine}>
                            {t("Liquidity code stabilizes prices in all markets.")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Ecosystem */}
                <div className={styles.ecoSystem}>
                  <div className={styles.ecoSystem__heading}>
                    <h2>{t("GoldPesa Ecosystem")}</h2>
                  </div>

                  <div className={styles.ecoSystem__cards}>
                    <div className={styles.card2}>
                      <div className={styles.card2__absoluteTop}>
                        {/* <div className={styles.card2__absoluteTop__left}>
                          <a
                            href="https://coinmarketcap.com/currencies/goldpesa/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={CoinMarketCap} alt="Coin Market Cap" />
                          </a>
                          <a
                            href="https://www.coingecko.com/en/coins/goldpesa-option"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={CoinGecko} alt="Coin Gecko" />
                          </a>
                        </div> */}

                        <div className={styles.card2__absoluteTop__right}>
                          <a
                            href="https://www.certik.com/projects/goldpesa"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={ExternalLink} alt="External Link" />
                            <p>{t("Certik Audit")}</p>
                          </a>
                        </div>
                      </div>

                      <div>
                        <figure className={styles.card2__figure}>
                          <img src={GPO} alt="GPO" />
                        </figure>

                        <h2 className={styles.card2__title}>
                          GoldPesa Option (GPO)
                        </h2>

                        <h3 className={styles.card2__subTitle}>
                        0x0308a3a9c433256aD7eF24dBEF9c49C8cb01300A
                        </h3>

                        <p className={styles.card2__gist}>
                          {t("1 GPO token gives you the option to convert to 1 GPX token.")}
                        </p>

                        <div className={styles.card2__attributes}>
                          <h3 className={styles.card2__attributes__heading}>
                            {t("Smart Contract")}
                          </h3>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>{t("Name")}: </span>
                              <span className={styles.bold}>
                                {t("GoldPesa Option")}
                              </span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>{t("Symbol")}: </span>
                              <span className={styles.bold}>{t("GPO")}</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Blockchain")}:{" "}
                              </span>
                              <span className={styles.bold}>
                                {t("Polygon")}
                              </span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Max Supply")}:{" "}
                              </span>
                              <span className={styles.bold}>100,000,000</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Fee On Swap")}:{" "}
                              </span>
                              <span className={styles.bold}>10%</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Max Tokens Per Wallet")}:
                              </span>
                              <span className={styles.bold}>100,000</span>
                            </p>
                          </div>
                        </div>

                        <div className={styles.card2__cta}>
                          <button type="button" onClick={launchAppFunc}>
                            {t("Buy Now")}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className={styles.card2}>
                      <div>
                        <figure className={styles.card2__figure}>
                          <img src={GPX} alt="GPO" />
                        </figure>

                        <h2 className={styles.card2__title}>GoldPesa (GPX)</h2>

                        <h3 className={styles.card2__subTitle}> </h3>

                        <p className={styles.card2__gist}>
                          {t("An advanced form of money fueled by the Pawn, our intelligent algorithm.")}
                        </p>

                        <div className={styles.card2__attributes}>
                          <h3 className={styles.card2__attributes__heading}>
                            {t("Smart Contract")}
                          </h3>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>{t("Name")}: </span>
                              <span className={styles.bold}>{t("GoldPesa")}</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>{t("Symbol")}: </span>
                              <span className={styles.bold}>{t("GPX")}</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Blockchain")}:{" "}
                              </span>
                              <span className={styles.bold}>
                                {t("Polygon")}
                              </span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Max Supply")}:{" "}
                              </span>
                              <span className={styles.bold}>100,000,000</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Fee On Transfer")}:{" "}
                              </span>
                              <span className={styles.bold}>1%</span>
                            </p>
                          </div>

                          <div className={styles.card2__attributes__item}>
                            <p>
                              <span className={styles.medium}>
                                {t("Release Date")}:{" "}
                              </span>
                              <span className={styles.bold}>{t("Q1 2025")}</span>
                            </p>
                          </div>
                        </div>

                        <div className={styles.card2__cta}>
                          <button type="button">{t("Q1 2025")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
