import React, { useState, useEffect, useRef, Fragment } from "react";
import styles from "./index.module.scss";
// import { FormControlLabel, Switch } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { customCloneDeep, debounce } from "utils/utilityFunctions";
import { initialHeaderConfig, launchAppLink } from "configs/headerConfigs";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
/* Logo & SVG Icons Below */
import Logo from "assets/images/goldpesa-logo-6.png";
import { ReactComponent as ChevronDownIcon } from "assets/images/icons/chevron-down.svg";
import { ReactComponent as TriangleIcon } from "assets/images/icons/triangle.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/images/icons/external-link.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import { ReactComponent as HamburgerIcon } from "assets/images/icons/hamburger.svg";
// import ReactCountryFlag from "react-country-flag";
import i18n from "i18next";
import en from "../../locales/en.json";
import fa from "../../locales/fa.json";

i18n.init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: { translation: en },
    fa: { translation: fa },
  },
});

const Header = () => {
  const { t } = useTranslation();
  const headerRef = useRef();
  const location = useLocation();
  const [headerConfig, setHeaderConfig] = useState(initialHeaderConfig);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPageScrolled, setIsPageScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const launchAppFunc = () => {
    window.location.href = launchAppLink;
  };

  const toggleActiveOption = (index, state) => {
    const headerConfigClone = customCloneDeep(headerConfig);

    headerConfigClone.forEach((item, i) => {
      if (index === i) {
        if (item.isActive !== state) item.isActive = state;
      }
    });

    setHeaderConfig(headerConfigClone);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prevState) => !prevState);
  };

  
  const changeHeaderBackground = () => {
    if (window.scrollY >= headerRef.current?.clientHeight / 2) {
      setIsPageScrolled(true);
    } else {
      setIsPageScrolled(false);
    }
  };

  const toggleModal = (state) => {
    setIsModalOpen(state);
  };

  useEffect(() => {
    if (isDrawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    window.addEventListener("scroll", changeHeaderBackground);
  
    return () => {
      window.removeEventListener("scroll", changeHeaderBackground);
    };
  }, []);

  useEffect(() => {
    if (location.search && location.search.includes("how-to-buy")) {
      if (window.scrollY !== 0) {
        window.scrollTo(0, 0);
  
        setTimeout(() => {
          toggleModal(true);
        }, 1000);
      } else {
        toggleModal(true);
      }
    }
  }, [location]);
  

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
  
    if (isModalOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isModalOpen]);

  return (
    <div
      ref={headerRef}
      id="header"
      className={`${styles.header} ${isPageScrolled ? styles.dark : ""}`}
    >
      <div className={styles.header__wrapper}>
        {/* Mobile Nagigation */}
        <div className={styles.mobileNavigation}>
          {/* Mobile Nagigation Button On Header */}
          <button
            type="button"
            className={`
            ${styles.mobileNavigation__button}
            ${isDrawerOpen ? styles.active : ""}
            `}
            onClick={toggleDrawer}
          >
            {isDrawerOpen ? <CloseIcon /> : <HamburgerIcon />}
          </button>

          {/* Mobile Nagigation Overlay */}
          <div
            className={styles.mobileNavigation__overlay}
            style={{ display: isDrawerOpen ? "block" : "none" }}
            onClick={toggleDrawer}
          ></div>

          {/* Mobile Nagigation Drawer */}
          <div
            className={`
              ${styles.mobileNavigation__drawer}
              ${isDrawerOpen ? styles.open : styles.close}
            `}
          >
            {/* Mobile Nagigation Top (Header) */}
            <div className={styles.mobileNavigation__top}>
              {/* Mobile Nagigation Button Inside Drawer */}
              <button
                type="button"
                className={`
                  ${styles.mobileNavigation__button}
                  ${isDrawerOpen ? styles.active : ""}
                `}
                onClick={toggleDrawer}
              >
                {isDrawerOpen ? <CloseIcon /> : <HamburgerIcon />}
              </button>

              {/* Mobile Nagigation Inside Logo */}
              <Link
                to="/"
                className={styles.header__logo}
                onClick={toggleDrawer}
              >
                <figure>
                  <img src={Logo} alt="Goldpesa Logo" />
                </figure>
              </Link>
            </div>

            {/* Mobile Nagigation Drawer Links List */}
            <div className={styles.mobileNavigation__links}>
              <ul className={styles.mobileNavigation__links__list}>
                {headerConfig?.length &&
                  headerConfig.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={styles.mobileNavigation__links__item}
                      >
                        {item.type === "link" ? (
                          <Link
                            to={item.link}
                            className={styles.mobileNavigation__links__link}
                            onClick={toggleDrawer}
                          >
                            {t(item.name)}
                          </Link>
                        ) : (
                          <Fragment>
                            <div
                              className={
                                styles.mobileNavigation__links__wrapper
                              }
                              onClick={() =>
                                toggleActiveOption(index, !item.isActive)
                              }
                            >
                              <span
                                className={styles.mobileNavigation__links__link}
                              >
                                {t(item.name)}
                              </span>

                              {item.type === "dropdown" && (
                                <span
                                  className={`${
                                    styles.mobileNavigation__links__icon
                                  } ${
                                    item.isActive
                                      ? styles[
                                          `mobileNavigation__links__icon--rotate`
                                        ]
                                      : ""
                                  }`}
                                >
                                  <ChevronDownIcon />
                                </span>
                              )}
                            </div>

                            {item?.dropdownLinks?.length && (
                              <ul
                                className={`${
                                  styles.mobileNavigation__links__innerList
                                } ${item.isActive ? styles.active : ""}`}
                              >
                                {item.dropdownLinks.map(
                                  (innerLinks, innerIndex) => {
                                    return (
                                      <li
                                        key={`${innerLinks.name}-${innerIndex}`}
                                        className={
                                          styles.mobileNavigation__links__innerItem
                                        }
                                        onClick={() =>
                                          toggleActiveOption(index, false)
                                        }
                                      >
                                        {innerLinks.isExternal ? (
                                          <a
                                            href={innerLinks.path}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                              styles.mobileNavigation__links__innerLink
                                            }
                                            onClick={toggleDrawer}
                                          >
                                            {t(innerLinks.name)}
                                          </a>
                                        ) : (
                                          <Link
                                            to={innerLinks.path}
                                            className={
                                              styles.mobileNavigation__links__innerLink
                                            }
                                            onClick={toggleDrawer}
                                          >
                                            {t(innerLinks.name)}
                                          </Link>
                                        )}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                          </Fragment>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>

            {/* Mobile Nagigation CTA Button  */}
            <div className={styles.mobileNavigation__cta}>
              <Button
                type="button"
                text={t("LAUNCH APP")}
                clickHandler={launchAppFunc}
              />
            </div>
          </div>
        </div>

        {/* Header Logo  */}
        <div className={styles.logoSection}>
          <Link
            to="/"
            className={`${styles.header__logo}`} 
          >
            <figure>
              <img src={Logo} alt="Goldpesa Logo" />
            </figure>
          </Link>
        </div>

        {/* Header Desktop Navigation  */}
        <div className={styles.navSection}>
          <nav className={styles.header__navigation}>
            <ul className={styles.navigationList}>
              {headerConfig?.length &&
                headerConfig.map((item, index) => {
                  return (
                    <li className={styles.navigationList__item} key={index}>
                      <div
                        className={styles.navigationList__item__wrapper}
                        onMouseEnter={() =>
                          debounce(toggleActiveOption(index, true))
                        }
                        onMouseLeave={() =>
                          debounce(toggleActiveOption(index, false))
                        }
                      >
                        <div
                          className={`${styles.navigationList__item__top} myLinkForCursor`}
                        >
                          <span className={styles.navigationList__item__name}>
                            {item.type === "link" ? (
                              <Link to={item.link}>{t(item.name)}</Link>
                            ) : (
                              <p>{t(item.name)}</p>
                            )}
                          </span>

                          {item.type === "dropdown" && (
                            <span
                              className={`${styles.navigationList__item__icon} ${
                                item.isActive
                                  ? styles[`navigationList__item__icon--rotate`]
                                  : ""
                              }`}
                            >
                              <ChevronDownIcon />
                            </span>
                          )}
                        </div>

                        {item.type === "dropdown" && (
                          <div
                            className={`${styles.navigationList__dropdown}  ${
                              item.isActive ? styles.show : styles.hide
                            }`}
                          >
                            <div
                              className={styles.navigationList__dropdown__wrapper}
                            >
                              <ul
                                className={styles.navigationList__dropdown__list}
                              >
                                {item.dropdownLinks &&
                                  item.dropdownLinks.map((link, index) => {
                                    return (
                                      <li
                                        key={`${link.name}-${index}`}
                                        className={
                                          styles.navigationList__dropdown__item
                                        }
                                        onClick={() =>
                                          debounce(
                                            toggleActiveOption(index, false)
                                          )
                                        }
                                      >
                                        {link.isExternal ? (
                                          <a
                                            href={link.path}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={
                                              styles.navigationList__dropdown__link
                                            }
                                          >
                                            {t(link.name)}
                                          </a>
                                        ) : (
                                          <Link
                                            to={link.path}
                                            className={
                                              styles.navigationList__dropdown__link
                                            }
                                          >
                                            {t(link.name)}
                                          </Link>
                                        )}

                                        <span
                                          className={
                                            styles.navigationList__dropdown__icon
                                          }
                                        >
                                          {link.isExternal ? (
                                            <ExternalLinkIcon />
                                          ) : (
                                            <TriangleIcon />
                                          )}
                                        </span>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </nav>
        </div>

        {/* Header Desktop CTA  */}
        <div className={styles.ctaSection}>
          <div className={styles.header__cta} style={{ marginRight: "20px" }}>
            <Button
              type="button"
              text={t("LAUNCH APP")}
              clickHandler={launchAppFunc}
            />
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className={styles.modal}>
          <div
            className={styles.modal__backdrop}
            onClick={() => toggleModal(false)}
          ></div>
          <div className={styles.modal__container}>
            <div className={styles.modal__video}>
              <div className={styles.modal__video__wrapper}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/BsyI9MFzGwk"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
