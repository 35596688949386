import React, { Fragment, useState, useEffect } from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { footerConfig } from "configs/footerConfig";

/* Logo & SVG Icons Below */
import Logo from "assets/images/gp-logo-footer.png";
import { ReactComponent as ExternalLinkIcon } from "assets/images/icons/external-link.svg";
import Button from "components/Button";

const Footer = () => {
  const [isWidth1440, setIsWidth1440] = useState(false);

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent) && window.innerWidth < 770;
  const isMobile2 = window.innerWidth <= 768;
  const isDesktop = window.innerWidth > 768;
  const launchAppFunc = () => {
    window.location.href = footerConfig.launchAppLink;
  };
  const { t } = useTranslation();


  useEffect(() => {
    const handleResize = () => {
      setIsWidth1440(window.innerWidth >= 1100 && window.innerWidth <= 1440);
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div id="footer" className={styles.footer}>
      <div className={styles.footer__wrapper}>
        <div className={styles.footer__mainArea}>
          <div className={styles.footer__left}>
            <Link to="/" className={styles.footer__logo}>
              <figure>
                <img src={Logo} alt="Goldpesa Logo" />
              </figure>
            </Link>

            <div className={styles.footer__address}>
            <p></p>
            </div>

            <div className={styles.footer__email}>
              <a href={`mailto:${footerConfig.email}`}>{t(footerConfig.email)}</a>
            </div>

            <div className={styles.footer__socialLinks}>
              <div className={styles.footer__socialLinks__wrapper}>
                {footerConfig &&
                  footerConfig.socialLinks.map((socialAddress, index) => {
                    return (
                      <span key={index} style={{ display: "inline-block" }}>
                        <a
                          href={socialAddress.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            // eslint-disable-next-line no-undef
                            src={require(`assets/images/social-icons/${socialAddress.id}.svg`)}
                            alt={socialAddress.name}
                          />
                        </a>
                      </span>
                    );
                  })}
                
              </div>
            </div>
          </div>

          <div className={styles.footer__right}>
            <div className={styles.footer__nav}>
              {footerConfig.navigationLinks.map((item, index) => {
                return (
                  <>
                    {(item.name === "Company" || item.name === "Resources") && (
                      <div key={index} className={styles.footer__nav__group}>
                        <>
                          {item.type === "link" ? (
                            <Link
                              to={item.link}
                              className={styles.footer__nav__title}
                              
                            >
                              {t(item.name)}
                            </Link>
                          ) : (
                            <h2 className={styles.footer__nav__title} style={{
                              marginLeft: (item.name === "Resources") ? "0px": ""
                            }}>
                              
                              {t(item.name)}
                            </h2>
                          )}
                          <ul
                            className={`${styles.footer__nav__list} ${
                              item.type === "link" ? styles.empty : ""
                            }`}
                            style={{
                              marginLeft: isMobile2 && item.name === "Company" ? "0px" : "",
                            }}
                          >
                            {item &&
                              item.dropdownLinks &&
                              Object.values(item.dropdownLinks).length &&
                              Object.values(item.dropdownLinks).map(
                                (link, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={styles.footer__nav__item}
                                    >
                                      {link.isExternal ? (
                                        <Fragment   >
                                          <a
                                            href={link.path}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={styles.footer__nav__link}
                                          >
                                            {t(link.name)}
                                          </a>
                                          <span
                                            className={styles.footer__nav__icon}
                                          >
                                            <ExternalLinkIcon />
                                          </span>
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <Link
                                            to={link.path}
                                            className={styles.footer__nav__link}
                                          >
                                            {t(link.name)}
                                          </Link>
                                        </Fragment>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                          </ul>

                       
                        </>
                      </div>
                    )}
                   
                  </>
                );
              })}

              {/* Footer CTA  */}
              <div className={`${styles.footer__cta} ${styles.mobileHidden}`}>
                <Button
                  type="button"
                  text={t("LAUNCH APP")}
                  variant="footer"
                  clickHandler={launchAppFunc}
                />
              </div>
            </div>

            <div className="footer-title" style={{
    width: isMobile2 ? '100%' : '56%',
    display: 'flex',
    marginTop: '15px',
    justifyContent: isMobile ? 'space-around' : 'center',
    alignItems: 'center',
    ...(isMobile && { justifyContent: 'space-around' }),
  }}>
  {footerConfig.navigationLinks.map(
    (item, index) =>
      (item.name === "Media & Press" || item.name === "How it Works?") && (
        <React.Fragment key={index}>
          <div>
            {item.type === "link" ? (
              <Link
              to={item.link}
              className={styles.footer__nav__title}
              style={{
                marginLeft: 
                  (window.innerWidth === 820 && item.name === "How it Works?") ? "0px" :
                  (window.innerWidth >= 1441 && item.name === "How it Works?") ? "-12px" :
                  item.name === "How it Works?" ? "18px" :
                  "",
                marginRight:
                  (window.innerWidth === 820 && item.name === "Media & Press") ? "0px" :
                  (isWidth1440 && item.name === "How it Works?") ? "17px" :
                  (window.innerWidth >= 820 && window.innerWidth <= 1099) ? "7px" :
                  "",
              }}
            >
                {t(item.name)}
              </Link>
            ) : (
              <h2 className={styles.footer__nav__title}>
                {t(item.name)}
              </h2>
            )}
          </div>
          {isDesktop && index < footerConfig.navigationLinks.length - 1 && (
  <div
    className="footer-item"
    style={{
      flexBasis: '24%',
      height: '0',
      margin: isMobile ? '5px 6px' : '10px',
    }}
  />
          )}
        </React.Fragment>
      )
  )}
</div>



              

          </div>
        </div>

        <div
  className={`${styles.footer} ${styles.desktopHidden} ${styles.btnCenter}`}
  style={{
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
    '@media (maxWidth: : 768px)': {
      visibility: 'visible',
    },
  }}
>
  <Button
    type="button"
    text={t("LAUNCH APP")}
    variant="footer"
    clickHandler={launchAppFunc}
  />
</div>

        <hr className={styles.footer__divider}></hr>

        <div className={styles.footer__bottom}>
          <p>{t("Copyright 2020 GoldPesa")}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
