export const footerConfig = {
  email: "admin@goldpesa.com ",
  address:
    "",
  socialLinks: [
    {
      id: "facebook",
      name: "Facebook",
      url: "https://www.facebook.com/GoldPesaOfficial",
    },
    {
      id: "instagram",
      name: "Instagram",
      url: "https://www.instagram.com/gold.pesa/",
    },
    {
      id: "twitter",
      name: "Twitter",
      url: "https://twitter.com/Goldpesatoken",
    },
    {
      id: "telegram",
      name: "Telegram",
      url: "https://t.me/GoldPesaOfficial",
    },
    {
      id: "discord",
      name: "Discord",
      url: "https://discord.com/invite/bq5j7hF2Fc",
    },
    {
      id: "medium",
      name: "Medium",
      url: "https://medium.com/@gold_pesa",
    },
    {
      id: "linkedin",
      name: "Linkedin",
      url: "https://www.linkedin.com/company/gold-pesa",
    },
    {
      id: "youtube",
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCptSwObtnIj2iEAMjO-3ERw",
    },
    {
      id: "git",
      name: "Git",
      url: "https://github.com/GoldPesa/goldpesa-core",
    },
  ],
  navigationLinks: [
    {
      name: "Company",
      dropdownLinks: [
        {
          name: "About",
          path: "/about-us",
          isExternal: false,
        },
        {
          name: "Team",
          path: "/team",
          isExternal: false,
        },
        {
          name: "Roadmap",
          path: "/roadmap",
          isExternal: false,
        },
        {
          name: "FAQ",
          path: "/faq",
          isExternal: false,
        },
      ],
    },
    // {
    //   name: "Ecosystem",
    //   dropdownLinks: [
    //     {
    //       name: "GoldPesa Mines",
    //       path: "/mines",
    //       isExternal: false,
    //     },
    //     {
    //       name: "GoldPesa Academy",
    //       path: "/academy",
    //       isExternal: false,
    //     },
    //     {
    //       name: "Stake2Win",
    //       path: "/stake-2-win",
    //       isExternal: false,
    //     },
    //     {
    //       name: "Xenii 2.0",
    //       path: "/xenii",
    //       isExternal: false,
    //     },
    //   ],
    // },
    {
      name: "Resources",
      dropdownLinks: [
        {
          name: "Whitepaper",
          path: "https://goldpesa-1.gitbook.io/goldpesa-whitepaper/",
          isExternal: true,
        },
        {
          name: "The Pawn",
          path: "https://medium.com/@gold_pesa/pawn-4-0-an-intelligent-source-of-liquidity-aa817001b06a",
          isExternal: true,
        },
        {
          name: "Pitch Deck",
          path: "https://medium.com/@gold_pesa/goldpesa-pitch-deck-goldpesas-value-proposition-in-2-minutes-38ad24156cd",
          isExternal: true,
        },
        {
          name: "Certik Audit",
          path: "https://www.certik.com/projects/goldpesa",
          isExternal: true,
        },
      ],
    },
  ],
  launchAppLink: "https://app.goldpesa.com/",
};
