/* For Desktop */
export const initialHeaderConfig = [
  {
    name: "Company",
    type: "dropdown",
    isActive: false,
    dropdownLinks: [
      {
        name: "About",
        path: "/about-us",
        isExternal: false,
      },
      {
        name: "Team",
        path: "/team",
        isExternal: false,
      },
      {
        name: "Roadmap",
        path: "/roadmap",
        isExternal: false,
      },
      {
        name: "FAQ",
        path: "/faq",
        isExternal: false,
      },
    ],
  },
  // {
  //   name: "Ecosystem",
  //   type: "dropdown",
  //   isActive: false,
  //   dropdownLinks: [
  //     {
  //       name: "GoldPesa Mines",
  //       path: "/mines",
  //       isExternal: false,
  //     },
  //     {
  //       name: "GoldPesa Academy",
  //       path: "/academy",
  //       isExternal: false,
  //     },
  //     {
  //       name: "Stake2Win",
  //       path: "/stake-2-win",
  //       isExternal: false,
  //     },
  //     {
  //       name: "Xenii 2.0",
  //       path: "/xenii",
  //       isExternal: false,
  //     },
  //   ],
  // },
  {
    name: "Resources",
    type: "dropdown",
    isActive: false,
    dropdownLinks: [
      {
        name: "Whitepaper",
        path: "https://goldpesa-1.gitbook.io/goldpesa-whitepaper/",
        isExternal: true,
      },
      {
        name: "The Pawn",
        path: "https://medium.com/@gold_pesa/pawn-4-0-an-intelligent-source-of-liquidity-aa817001b06a",
        isExternal: true,
      },
      {
        name: "Pitch Deck",
        path: "https://medium.com/@gold_pesa/goldpesa-pitch-deck-goldpesas-value-proposition-in-2-minutes-38ad24156cd",
        isExternal: true,
      },
      {
        name: "Certik Audit",
        path: "https://www.certik.com/projects/goldpesa",
        isExternal: true,
      },
    ],
  },
  {
    name: "Media & Press",
    type: "link",
    link: "/media-and-press",
    isActive: false,
  },
  {
    name: "How it Works?",
    type: "link",
    link: "?how-to-buy",
    isActive: false,
  },
];

export const launchAppLink = "https://app.goldpesa.com/";
